import React from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { FiArrowUpRight } from 'react-icons/fi';
import { DecisionDateFormat } from '../../../services/constants';
import { EditButton } from './EditButton';

const titles = {
  Video: 'video',
  Audio: 'audio',
  Post: 'post',
  Document: 'document',
  Article: 'article',
  Ebook: 'eBook',
};

export function Tabs({
  tab, setTab, item, isAdmin, linkedContent,
}) {
  const ruleTypeParser = (type) => type.replace(/([a-z](?=[A-Z]))/g, '$1 ').split(' ')[0].toLowerCase(); // FederalRules -> federal
  const parsedTranscription = item.post_body ? ReactHtmlParser(item.post_body) : 'No transcript available for current video.';

  return (
    <>
      <div className="tabs">
        <div className={`tab ${tab === 'about' && 'active'}`} onClick={() => setTab('about')}>
          About this
          {' '}
          {titles[item.type]}
        </div>
        <div className={`tab ${tab === 'resources' && 'active'}`} onClick={() => setTab('resources')}>
          Resources
        </div>
        {item.type === 'Video'
          && (
          <div className={`tab ${tab === 'transcript' && 'active'}`} onClick={() => setTab('transcript')}>
            Transcript
          </div>
          )}
      </div>
      <div className="tab-content">
        { tab === 'about' && (
        <>
          { (item.partner_logo && item.partner_logo.url) && <img src={item.partner_logo?.url} alt="Partner logo" className="partner-logo" style={{ width: '20%' }} /> }
          { (item.type === 'Video' || item.type === 'Audio') && (
          <h1>
            {item.title}
            {isAdmin && <EditButton mediaItem={item} />}
          </h1>
          )}
          { item.tags?.length > 0 && (
          <div className="tags">
            { item.tags?.map((tag) => (
              <span className="tag">{tag.name}</span>
            ))}
          </div>
          )}

          { (item.type === 'Video' || item.type === 'Audio' || item.tags?.length > 0) && (
          <div className="separator" />
          )}

          <p className="description-header">Description</p>
          <div className="description">
            {item.description}
          </div>
        </>
        )}

        { tab === 'resources' && (
        <>
          { (linkedContent.CaseDigest || []).map((relatedCase) => {
            const rules = [...relatedCase.state_rules, ...relatedCase.federal_rules, ...relatedCase.district_rules];
            const {
              additional_filings, checklists, templates, tips,
            } = relatedCase;
            return (
              <>
                <div className="resource">
                  <span className="type">Case Law:</span>
                  <a href={`/case_law/${relatedCase.slug}`} target="_blank" rel="noreferrer">
                    {relatedCase.published_revision.name}
                    <FiArrowUpRight />
                  </a>
                </div>
                { additional_filings?.length > 0
              && (
              <div className="resource">
                <span className="type">Additional Decisions:</span>
                <div className="related-rules">
                  { additional_filings?.map((additionalItem) => (
                    <a href={`/case_law/${additionalItem.slug}`} target="_blank" rel="noreferrer">
                      { additionalItem.published_revision.name }
                      {' '}
                      (
                      {additionalItem.published_revision.cite}
                      ,
                      {' '}
                      {additionalItem.published_revision.decision_date && moment(additionalItem.published_revision.decision_date).format(DecisionDateFormat)}
                      )
                      <FiArrowUpRight />
                    </a>
                  ))}
                </div>
              </div>
              )}
                { rules?.length > 0
              && (
              <div className="resource">
                <span className="type">Related case rules:</span>
                <div className="related-rules">
                  { rules?.map((rule) => (
                    <a href={`/${ruleTypeParser(rule.type)}_rules/${rule.id}`} target="_blank" rel="noreferrer">
                      {rule.published_revision.number}
                      <FiArrowUpRight />
                    </a>
                  ))}
                </div>
              </div>
              )}
                { checklists?.length > 0
              && (
              <div className="resource">
                <span className="type">Related Checklists:</span>
                <div className="related-rules">
                  { checklists?.map((checklist) => (
                    <a href={`/checklists/${checklist.id}`} target="_blank" rel="noreferrer">
                      { checklist.published_revision.name }
                      <FiArrowUpRight />
                    </a>
                  ))}
                </div>
              </div>
              )}
                { templates?.length > 0
              && (
              <div className="resource">
                <span className="type">Related Templates:</span>
                <div className="related-rules">
                  { templates?.map((template) => (
                    <a href={template.published_revision?.document.url} target="_blank" rel="noreferrer">
                      { template.published_revision.name }
                      <FiArrowUpRight />
                    </a>
                  ))}
                </div>
              </div>
              )}
                { tips?.length > 0
              && (
              <div className="resource">
                <span className="type">Related Tips:</span>
                <div className="related-rules">
                  { tips?.map((tip) => (
                    <>
                      <a>{ tip.name }</a>
                      <span className="type">{ tip.description }</span>
                    </>
                  ))}
                </div>
              </div>
              )}
              </>
            );
          })}

          { (linkedContent.Rule || []).map((rule) => (
            <div className="resource">
              <span className="type">Rules:</span>
              <a href={`/${ruleTypeParser(rule.type)}_rules/${rule.id}`} target="_blank" rel="noreferrer">
                {rule.published_revision.number}
                <FiArrowUpRight />
              </a>
            </div>
          ))}

          { (linkedContent.Checklist || []).map((checklist) => (
            <div className="resource">
              <span className="type">Checklists:</span>
              <a href={`/checklists/${checklist.id}`} target="_blank" rel="noreferrer">
                {checklist.published_revision.name}
                <FiArrowUpRight />
              </a>
            </div>
          ))}

          { (linkedContent.Template || []).map((form) => (
            <div className="resource">
              <span className="type">Forms:</span>
              <a href={`/forms/${form.id}`} target="_blank" rel="noreferrer">
                {form.published_revision.name}
                <FiArrowUpRight />
              </a>
            </div>
          ))}

          { (linkedContent.GlossaryTerm || []).map((term) => (
            <div className="resource">
              <span className="type">Glossary:</span>
              <a href={`/glossary_terms?term=${term.published_revision.id}`} target="_blank" rel="noreferrer">
                {term.published_revision.name}
                <FiArrowUpRight />
              </a>
            </div>
          ))}
        </>
        )}

        { tab === 'transcript' && (
        <div className="description">
          { parsedTranscription }
        </div>
        )}
      </div>
    </>
  );
}
