import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { GoSearch, FaBolt } from 'react-icons/all';
import { ClipLoader } from 'react-spinners';
import api from '../../services/api';

export default function AddToCiteListSection({ caseDigestIds, closeSection }) {
  const [citeLists, setCiteLists] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [addedResults, setAddedResults] = useState(null);
  const [conflictingResults, setConflictingResults] = useState(null);
  const [targetCiteList, setTargetCiteList] = useState('');
  const [mode, setMode] = useState('add');
  const [newCiteListName, setNewCiteListName] = useState('');
  const [listErrors, setListErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchCiteLists = () => api.cite_lists.get({ withShared: true }).then((response) => {
    setCiteLists(response.message);
  });

  useEffect(() => {
    fetchCiteLists();
  }, []);

  const addToCiteList = async (citeList) => {
    setAddedResults(null);
    setConflictingResults(null);
    setTargetCiteList('');
    setIsLoading(true);

    const res = await api.cite_list_cases.mass_assign({
      cite_list_case: {
        cite_list_id: citeList.id,
        case_digest_ids: caseDigestIds,
      },
    });

    setAddedResults(Number(res.message.added_digests));
    setConflictingResults(Number(res.message.conflicting_digests));
    setTargetCiteList(res.message.cite_list_name);
    setListErrors('');
    setIsLoading(false);
    fetchCiteLists();
  };

  const createCiteList = async () => {
    setIsLoading(true);
    const { status, message, cite_list: citeList } = await api.cite_lists.post({ name: newCiteListName });
    if (status === 'OK') {
      setListErrors('');
      await addToCiteList(citeList);
    } else {
      setListErrors(message);
      setIsLoading(false);
      setAddedResults(null);
      setConflictingResults(null);
    }
    setMode('add');
  };

  const fetchSuggestions = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const suggestions = inputValue.length === 0 ? [] : citeLists.filter((item) => item.name.toLowerCase().slice(0, inputValue.length) === inputValue.toLowerCase());

    setSearchSuggestions(suggestions);
  };

  const clearSearchSuggestions = () => {
    setSearchSuggestions([]);
  };

  const selectSearchSuggestion = (e, { suggestion }) => {
    addToCiteList(suggestion);
    setSearchValue('');
    setSearchSuggestions([]);
  };

  const searchInputProps = {
    placeholder: 'Search Cite Lists',
    value: searchValue,
    onChange: (e, { newValue }) => setSearchValue(newValue),
  };

  const renderAdd = () => (
    <div className="add-to-cite-list side-section">
      {isLoading && (
        <div className="loader">
          <ClipLoader color="rgba(0, 14, 87, 1)" />
        </div>
      )}
      <div className="side-section-header">
        <h4 className="side-section-title">
          Add Results to Cite List
          <button
            type="button"
            className="button-clear"
            onClick={closeSection}
          >
            ✕
          </button>
        </h4>
        <div className="create-cite-list-row">
          <p className="side-section-description">
            To add to an existing cite list, search cite lists, or click button to add a new cite list.
          </p>
          <button type="button" className="btn btn-primary" onClick={() => setMode('create')}>
            Add New
          </button>
        </div>
      </div>
      <div className="side-section-body">
        <div className="search">
          <Autosuggest
            suggestions={searchSuggestions}
            shouldRenderSuggestions={() => true}
            onSuggestionsFetchRequested={fetchSuggestions}
            onSuggestionsClearRequested={clearSearchSuggestions}
            getSuggestionValue={(item) => item.name}
            renderSuggestion={(item) => (
              <div className="suggestion">{item.name}</div>
            )}
            inputProps={searchInputProps}
            onSuggestionSelected={selectSearchSuggestion}
          />
          <GoSearch />
        </div>

        {addedResults !== null && conflictingResults !== null && (
          <>
            <div className="side-section-message success" data-testid="test-message">
              {addedResults ? (
                <>
                  {addedResults === caseDigestIds.length ? 'All' : addedResults}
                  {' '}
                  result
                  {addedResults === 1 && caseDigestIds.length > 1 ? '' : 's'}
                  {' '}
                  {addedResults === 1 && caseDigestIds.length > 1 ? 'has' : 'have'}
                  {' '}
                  been added to “
                  {targetCiteList}
                  ”
                </>
              ) : (
                <>
                  No results have been added to
                  {' '}
                  {' '}
                  “
                  {targetCiteList}
                  ”
                  .
                </>
              )}
            </div>
            {conflictingResults && (
              <div className="side-section-message warning">
                {conflictingResults === caseDigestIds.length ? 'All' : conflictingResults}
                {' '}
                result
                {conflictingResults === 1 && caseDigestIds.length > 1 ? '' : 's'}
                {' '}
                {conflictingResults === 1 && caseDigestIds.length > 1 ? 'was' : 'were'}
                {' '}
                already in “
                {targetCiteList}
                ”
              </div>
            )}
          </>
        )}
        {listErrors !== '' && (
          <div className="side-section-message cite-list-warning">
            { listErrors }
          </div>
        )}
        {
          citeLists.map((citeList) => (
            <div key={citeList.id} className="side-section-item">
              <div className="side-section-item-name">
                {citeList.name}
                <span style={{ flex: 1 }} />
                {citeList.search_query && <FaBolt />}
                {citeList.search_query ? citeList.search_count : citeList.cases_count}
              </div>
              {!citeList.search_query && (
                <button
                  className="btn btn-primary"
                  onClick={() => addToCiteList(citeList)}
                >
                  Add
                  { (caseDigestIds && caseDigestIds.length > 1) && ' All' }
                </button>
              )}
            </div>
          ))
        }
      </div>
    </div>
  );

  const renderCreate = () => (
    <div className="side-section add-to-cite-list">
      {isLoading && (
        <div className="loader">
          <ClipLoader color="rgba(0, 14, 87, 1)" />
        </div>
      )}
      <div className="side-section-header">
        <h4 className="side-section-title">
          Add New Cite List
          <button
            type="button"
            className="button-clear"
            onClick={closeSection}
          >
            ✕
          </button>
        </h4>
      </div>
      <div className="side-section-body">
        <input
          className="new-cite-list-input"
          value={newCiteListName}
          onChange={(e) => setNewCiteListName(e.target.value)}
          placeholder="Enter Cite List Name"
        />
        <button
          type="button"
          disabled={!newCiteListName}
          className="btn btn-primary"
          onClick={createCiteList}
        >
          Create Cite List
        </button>
      </div>
    </div>
  );

  return mode === 'add' ? renderAdd() : renderCreate();
}

AddToCiteListSection.propTypes = {
};

AddToCiteListSection.defaultProps = {
};
