import React from 'react';
import {
  GoKebabVertical, FaPowerOff, FaUserCircle, FaExclamationCircle, GoChevronRight,
} from 'react-icons/all';
import Menu, { SubMenu, MenuItem, Divider } from 'rc-menu/es';
import 'rc-menu/assets/index.css';

export function UserMenu({ currentUser, isAdmin, openFeedbackModal }) {
  const renderAdminMenu = () => (
    <SubMenu
      className="settings-item has-items"
      popupClassName="settings-submenu"
      title={(
        <>
          <FaUserCircle className="icon" />
          Admin
        </>
          )}
    >
      <MenuItem className="settings-item">
        <a href="/admin/accounts">Accounts</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/users">Users</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/rules">Rules</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/case_digests">Case Law</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/categories">Categories</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/checklists">Checklists</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/templates">Forms</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/glossary_terms">Glossary Terms</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/resources">Resources</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/tags">Tags</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/tips">Tips</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/categories">Categories</a>
      </MenuItem>
      <MenuItem className="settings-item">
        <a href="/admin/academy">Academy</a>
      </MenuItem>
    </SubMenu>
  );

  return (
    <Menu
      mode="horizontal"
      triggerSubMenuAction="click"
      expandIcon={<GoChevronRight style={{ verticalAlign: 'middle' }} />}
      className="user-item"
    >
      <SubMenu title={<GoKebabVertical />} className="settings" popupClassName="settings-submenu">
        <MenuItem className="user-settings-item">
          <a href="/account/edit#profile">
            <FaUserCircle className="icon user-icon" style={{ height: 25, width: 25 }} />
            { currentUser.first_name }
            {' '}
            { currentUser.last_name }
          </a>
        </MenuItem>
        <Divider />
        <MenuItem className="settings-item">
          <a onClick={openFeedbackModal}>
            <FaExclamationCircle className="icon" />
            Send Feedback
          </a>
        </MenuItem>
        <Divider />
        <MenuItem className="settings-item">
          <a href="/account/edit#profile">
            <FaUserCircle className="icon" />
            Account Settings
          </a>
        </MenuItem>
        { isAdmin && renderAdminMenu() }
        <MenuItem className="settings-item">
          <a rel="nofollow" data-method="delete" href="/logout">
            <FaPowerOff className="icon" />
            Logout
          </a>
        </MenuItem>
      </SubMenu>
    </Menu>
  );
}
