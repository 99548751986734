import React, {useEffect, useState} from 'react';
import { Document, Page } from 'react-pdf';
import FileViewer from 'react-file-viewer';
import { Tabs } from './Tabs';
import { EditButton } from './EditButton';
import { Pages } from './Pages';

export function FileContent({
  mediaItem, tab, setTab, isAdmin, linkedContent,
}) {
  const [pages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  useEffect(() => {
    const div = document.getElementById('right');
    if (div) div.scrollTop = 0;
  }, [pageNumber]);

  const onPageLoad = (page) => {
    const parentDiv = document.querySelector('#pdf-container');
    const pageScale = parentDiv.clientWidth / page.originalWidth;
    if (scale !== pageScale) setScale(pageScale);
  };

  if (!mediaItem.document.url) {
    return (
      <div className="content document-view">
        <h1>No active link</h1>
      </div>
    );
  }

  return (
    <div className="content document-view">
      <h1>
        {mediaItem.title}
        {isAdmin && <EditButton mediaItem={mediaItem} />}
      </h1>
      <div className="separator" />
      <div className="document" id="pdf-container">
        { mediaItem.document.url.includes('.docx') ? (
          <FileViewer
            fileType="docx"
            filePath={mediaItem.document.url}
          />
        ) : (
          <Document
            file={mediaItem.document.url}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page
              pageNumber={pageNumber}
              renderMode="svg"
              scale={scale}
              onLoadSuccess={onPageLoad}
            />
          </Document>
        )}
      </div>
      { (!mediaItem.document.url.includes('.docx') && pages) && <Pages pageNumber={pageNumber} pages={pages} setPageNumber={setPageNumber} />}
      <Tabs
        item={mediaItem}
        tab={tab}
        setTab={setTab}
        isAdmin={isAdmin}
        linkedContent={linkedContent}
      />
    </div>
  );
}
